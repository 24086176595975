.masthead {
  min-height: 100px;
  padding: 3px;
  color: #ffffff;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: 400;
  font-style: normal;
  line-height: 2em;
  font-family: 'Roboto-Thin', sans-serif !important;
}
 
.masthead a {
  color: #ffffff !important;
  text-decoration: none !important;
}

/* https://cssgradient.io */
.gradient1 {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #000000, #3f5efb, #fc466b);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #000000, #3f5efb, #fc466b );
}

.gradient2 {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #000000, #3c7909, #ffbe00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #000000, #3c7909, #ffbe00 );
}

.gradient3 {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #000000, #22c1c3, #fdbb2d);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #000000, #22c1c3, #fdbb2d );
}

.gradient4 {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #000000, #41446d , #ca649a);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #000000, #41446d, #ca649a );
}

.gradient5 {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #000000, #101f6e , #c6bc05);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #000000, #101f6e, #c6bc05 );
}

.logo {
  background-color: #000000 !important;
}